.therapy-tracker-container {
  border: 2px solid #b7b7b7;
  border-radius: 5px;
  background-color: white;
  font-family: "Open Sans";
  padding: 1rem;

  h6 {
    color: #212121;
    margin: 1rem 0rem;
  }

  ol {
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  li {
    margin: 0.5rem;
  }

  ol li::marker {
    font-weight: bold;
  }
}

.therapy-tracker-header {
  margin-top: 0rem !important;
}

.therapy-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.therapy-actions {
  display: flex;
  gap: 0.25rem;
}

.therapy-modal {
  .therapy-modal-input {
    width: 20rem;
  }

  .therapy-modal-footer {
    display: flex;
    justify-content: space-between;
  }
}
