.followUpFormContainer {
  $blue: #007bff;
  $dark-blue: #106995;

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 66.67%;
  margin: 0 auto;

  .header {
    text-align: left;
    margin-bottom: 2rem;
  }

  .required {
    color: red;
  }

  .error {
    font-size: 0.875rem;
    color: red;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .form-text {
    font-size: 1rem;
  }

  .inline-buttons {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
  }

  .other-input-button {
    width: 3rem;
    margin-left: -1.5rem;
  }

  .form-check {
    accent-color: $blue;
  }

  .slider-blue {
    accent-color: $blue;
  }

  .percentageMarkers {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    option {
      font-family: Quicksand;
      text-align: center;
      transform: translateX(40%);
    }
  }

  .form-label {
    font-family: Quicksand;
  }

  .divider {
    background-color: $dark-blue;
    height: 1px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
}
