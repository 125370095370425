.medication-history-container {
  $dark-blue: #106995;

  display: flex;
  flex-direction: column;
  background-color: white;
  border: 2px solid $dark-blue;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  gap: 0.5rem;

  .medication-item {
    display: flex;
    flex-direction: row;
    border: 2px solid $dark-blue;
    border-radius: 5px;
    padding: 0.5rem;
  }
}
