.factor-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  $peacock-blue: #106995;
  $verdigris: #3eb1a6;

  h5 {
    font-family: "Quicksand";
    font-size: 20px;
    text-align: center;
  }

  .select-factors {
    text-align: center;
    width: 66.667%;

    .select-header {
      margin-bottom: 1.5rem;
    }

    .select-factors-pane {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .category-pane {
        display: flex;
        flex-direction: column;
        gap: 0rem;

        .category-item {
          border: 1px solid $peacock-blue;
          border-radius: 0.25rem;
          font-family: "Open Sans";
          text-align: center;
          padding: 0.5rem 1rem;

          &:hover {
            cursor: pointer;
            color: $verdigris;
          }
        }
      }

      .factor-pane {
        background-color: white;
        border: 2px solid $peacock-blue;
        border-radius: 8px;
        height: 30rem;
        overflow: scroll;
        margin-right: 1rem;
        width: 100%;
        padding: 0.5rem;
        text-align: left;

        span {
          font-family: "Open Sans";
          font-size: 1rem;
          font-style: italic;
          font-weight: 400;
        }
      }
    }
  }

  .factors-list {
    display: flex;
    flex-direction: column;
  }

  .factor-item {
    width: fit-content;
    padding: 0.25rem 0.75rem;
    cursor: pointer;

    &:hover {
      background-color: rgba(62, 177, 166, 0.25);
      border-radius: 4px;
    }

    .custom-checkbox-container {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      cursor: pointer;
      margin: 0;
      padding: 0.25rem;
    }

    input[type="checkbox"] {
      display: none;
    }
  }

  .other-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .other-input-field {
      display: inline-block;
      border: 0;
      border-bottom: 1px solid black;
      outline: none;
      width: 50%;
      margin-bottom: 1rem;
      margin-left: 0.25rem;
    }
  }

  .selected-factors {
    width: 33.334%;

    .selected-header {
      margin-bottom: 3rem;
    }

    .selection-pane {
      background-color: white;
      border: 1px solid $peacock-blue;
      border-radius: 8px;
      height: 30rem;
      overflow: scroll;
      padding: 1.5rem 1rem;
    }
  }
}
