.hemispheric-sum-container {
  font-family: "Quicksand";
  background-color: #e5ab40;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  max-width: 335px;
  margin: 1rem 0.5rem;
  padding-top: 0.5rem;

  .hemispheric-sum-header {
    text-align: center;
    font-size: 1.2rem;
    color: white;
  }

  .hemispheric-sum-row {
    display: flex;
    justify-content: space-evenly;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      div {
        font-size: 1rem;
        color: #555;
      }

      p {
        font-size: 1.2rem;
        color: black;
      }
    }
  }
}
